import * as React from "react";

function FR30({ theme = "red" }) {
  return (
    <svg
      width='47'
      height='47'
      viewBox='0 0 46 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icoRewind30'>
        <g id='Group 9'>
          <path
            id='Stroke 1'
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M26.7813 16.7363C25.9963 17.8227 25.5898 19.3322 25.5533 21.2633L25.5289 23.4362L25.5533 25.6643C25.5898 27.634 25.9963 29.1512 26.7813 30.2196C27.5627 31.288 28.7092 31.8203 30.2209 31.8203C31.7337 31.8203 32.8802 31.288 33.6628 30.2196C34.4454 29.1512 34.8532 27.634 34.8897 25.6643C34.9067 25.1269 34.9165 24.3837 34.9165 23.4362C34.9165 22.4899 34.9067 21.7647 34.8897 21.2633C34.8532 19.3322 34.4454 17.8227 33.6628 16.7363C32.8802 15.6499 31.7337 15.106 30.2209 15.106C28.7092 15.106 27.5627 15.6499 26.7813 16.7363Z'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 3'
            d='M9.63265 15.7959L3.41933 18.5486L1.60828 11.8102'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 5'
            d='M22.8686 45.2605C33.7045 45.7954 43.0859 37.383 44.3468 26.0005C45.6078 14.6181 38.3173 4.14595 27.6579 2.02324C16.9973 -0.0981919 6.59833 6.85238 3.85132 17.9391'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 7'
            d='M13.3328 27.3422C13.5044 29.2952 14.7945 30.9358 16.58 31.4706C18.3667 32.0068 20.28 31.3266 21.3961 29.7593C22.5134 28.192 22.601 26.0603 21.6213 24.3953C20.639 22.7316 18.7878 21.8753 16.9646 22.243L22.5572 15.2758H15.285'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  );
}

export default FR30;

import moment from 'moment';
import _ from 'lodash';

export const parseTime = ( strTime ) => {
	const time = moment( strTime, "h:mm:ss" );
	return time.diff( time.clone().startOf('day'), 'seconds');
}

export const stringifyTime = ( time ) => {
	if( _.isNumber( time ) ){
		return moment().startOf('day').seconds( time ).format('H:mm:ss')
	}else{
		return null;
	}
}
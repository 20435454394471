import { hot } from "react-hot-loader/root";

import React from "react";
import {
  BrowserRouter as Router,
  // HashRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import _ from "lodash";

import * as Dictionary from "../util/Dictionary";

import "./styles/App.styl";
import Note from "./Note";
import OhNo404 from "./OhNo404";

import TRANSLATIONS from "../assets/translations.csv";
import BaseComponent from "./BaseComponent";
import DictionaryContextProvider from "./DictionaryContextProvider";
import ThemeContextProvider from "./ThemeContextProvider";
import { themeHexChanger } from "../util/ThemeChanger";

const $ = window.$;
/*
	THIS IS THE MAIN ENTRY POINT FOR THE APPLICATION
*/

function getQueryAttr(id, defaultValue) {
  //use function to check for the language on the query string
  if (window.URLSearchParams) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(id) || defaultValue;
  }

  return defaultValue;
}

class App extends BaseComponent {
  state = {
    translations: null,
    langauge: null,
    theme: null,
    themeHex: null
  };

  componentDidMount() {
    //load the translations from a CSV file
    Dictionary.loadCsv(TRANSLATIONS).then(({ keys, items }) => {
      //receive the items and the keys (languages)
      var language = getQueryAttr("language"); //this is our default language if the func returns undefined
      var theme = getQueryAttr("theme"); //this is our default theme
      const themeHex = themeHexChanger(theme);
      //only allow supported languages
      language = _.includes(keys, language) ? language : "en";

      //create the translations provider and update our state
      this.setState({
        translations: Dictionary.create(items, {
          keys,
          defaultLanguage: language
        }),
        language,
        theme: themeHex
      });
    });
  }

  onLanguageChange(language) {
    //language has to be set on the root level state as it is required everywhere
    this.setState({ language });
  }

  render() {
    const { translations, language, theme } = this.state;
    //ensure we have translations
    if (!translations) return null;
    //return the app
    return (
      <ThemeContextProvider theme={theme}>
        <DictionaryContextProvider
          dictionary={translations}
          language={language}
        >
          {/* THE MAIN CONTAINER (sets fonts and dark mode) */}
          <ThemeContextProvider.Container as='div' className='App'>
            <Router>
              <Switch>
                {/* OUR NOTES */}
                <Route
                  exact
                  path={"/note/:noteId"}
                  render={path => {
                    const {
                      match: { params }
                    } = path;
                    return (
                      <Note
                        {...params}
                        theme={this.state.theme}
                        onLanguageChange={this.onLanguageChange}
                        onNotFound={() => {
                          //Note not found - redirect to 404
                          window.location.pathname = "/404";
                        }}
                      />
                    );
                  }}
                />
                {/* 404 */}
                <Route path='/404' component={OhNo404} />
                <Route
                  path='/samples'
                  render={() => (
                    <div className={"Samples Note"}>
                      <a
                        href='https://cvws.icloud-content.com/B/Ac1pwv3HYrbjolrsciYC7_bDD5tIAaTqD1zgbaSzFVD3eHjn2rarM-4q/hhh.txt?o=AoJTTIZn-EZMYPTBS4bg8-Ijx_l2ZozlegAPoPSSC8kv&v=1&x=3&a=CAoga-m2FyxMidHbx9K7PNs21KojxhFsaligj_KC7BbM_7ISaxDXnL2KpS4Y16y43q4uIgEAUgTDD5tIWgSrM-4qaiUDKPecZc_jHm5AyEXPpLc7IRthZYrXbH4mxJn0xowEOedauSS1ciWMiJ-24RCEBWqyJwLJ7Eh2NvHHWAi2wNo8Zf5kMr5PcxOKkN16&e=1593094051&fl=&r=38615993-A455-48D5-82C6-1AFB9519230F-1&k=xPHY6j1izxfoqdmKhciJVg&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=43&s=4WD4AEYeL8iyONcajrL1I5VyFWI'
                        data-attachment='https://cvws.icloud-content.com/B/Ac1pwv3HYrbjolrsciYC7_bDD5tIAaTqD1zgbaSzFVD3eHjn2rarM-4q/hhh.txt?o=AoJTTIZn-EZMYPTBS4bg8-Ijx_l2ZozlegAPoPSSC8kv&v=1&x=3&a=CAoga-m2FyxMidHbx9K7PNs21KojxhFsaligj_KC7BbM_7ISaxDXnL2KpS4Y16y43q4uIgEAUgTDD5tIWgSrM-4qaiUDKPecZc_jHm5AyEXPpLc7IRthZYrXbH4mxJn0xowEOedauSS1ciWMiJ-24RCEBWqyJwLJ7Eh2NvHHWAi2wNo8Zf5kMr5PcxOKkN16&e=1593094051&fl=&r=38615993-A455-48D5-82C6-1AFB9519230F-1&k=xPHY6j1izxfoqdmKhciJVg&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=43&s=4WD4AEYeL8iyONcajrL1I5VyFWI'
                      >
                        <span className='title'>hhh.txt</span>
                        <span className='subtitle'>
                          13 Apr 2020 • 211 bytes
                        </span>
                      </a>
                    </div>
                  )}
                />
                {/* Redirect to 404 if path not recognised */}
                <Route
                  path='/'
                  exact
                  render={() => {
                    if (window.location.hash) {
                      var path =
                        window.location.hash.replace(/#/, "") +
                        window.location.search;
                      return <Redirect to={path} />;
                    } else {
                      return <Redirect to='/404' />;
                    }
                  }}
                />
                <Route render={() => <Redirect to='/404' />} />
              </Switch>
            </Router>
          </ThemeContextProvider.Container>
        </DictionaryContextProvider>
      </ThemeContextProvider>
    );
  }
}

//Developer environment uses hotloading
export default hot(App);

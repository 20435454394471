import * as React from "react";

function FR60({ theme = "red" }) {
  return (
    <svg
      width='47'
      height='47'
      viewBox='0 0 46 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icoRewind60'>
        <g id='Group 9'>
          <path
            id='Stroke 1'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M26.281 16.7363C25.4959 17.8227 25.0894 19.3322 25.0529 21.2633L25.0286 23.4362L25.0529 25.6643C25.0894 27.634 25.4959 29.1512 26.281 30.2196C27.0636 31.288 28.2089 31.8203 29.7205 31.8203C31.2334 31.8203 32.3799 31.288 33.1637 30.2196C33.9451 29.1512 34.354 27.634 34.3893 25.6643C34.4064 25.1269 34.4161 24.3837 34.4161 23.4362C34.4161 22.4899 34.4064 21.7647 34.3893 21.2633C34.354 19.3322 33.9451 17.8227 33.1637 16.7363C32.3799 15.6499 31.2334 15.106 29.7205 15.106C28.2089 15.106 27.0636 15.6499 26.281 16.7363Z'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 3'
            d='M9.13229 15.7959L2.91896 18.5486L1.10791 11.8102'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 5'
            d='M22.3682 45.2605C33.2041 45.7954 42.5856 37.383 43.8465 26.0005C45.1086 14.6181 37.8182 4.14595 27.1575 2.02324C16.4969 -0.0981919 6.09918 6.85238 3.35095 17.9391'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 7'
            d='M18.0395 15.1066L13.0908 22.7116C12.8985 23.006 12.6563 23.4431 12.4396 23.852C11.8457 24.9757 11.5572 26.291 11.8177 27.5497C12.2266 29.5233 13.6725 31.2114 15.7891 31.7013C17.078 31.9996 18.4631 31.7321 19.567 30.971C22.1618 29.1851 22.6536 25.6224 20.9399 23.1744C19.1556 20.6261 15.5761 20.1427 13.1918 22.556'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  );
}

export default FR60;

import * as React from "react";

function FF60({ theme = "red" }) {
  return (
    <svg
      width='47'
      height='47'
      viewBox='0 0 46 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icoFF60'>
        <g id='Group 9'>
          <path
            id='Stroke 1'
            d='M35.9651 15.796L42.1784 18.5487L43.9895 11.8102'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 3'
            d='M22.729 45.2605C11.8931 45.7954 2.51166 37.383 1.25074 26.0005C-0.0114026 14.6181 7.27906 4.14595 17.9397 2.02324C28.6003 -0.0981919 38.998 6.85238 41.7463 17.9391'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 5'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M24.0496 16.7364C23.2646 17.8228 22.8581 19.3322 22.8216 21.2634L22.7972 23.4362L22.8216 25.6644C22.8581 27.6341 23.2646 29.1512 24.0496 30.2196C24.8322 31.2881 25.9775 31.8204 27.4892 31.8204C29.002 31.8204 30.1486 31.2881 30.9324 30.2196C31.7138 29.1512 32.1227 27.6341 32.158 25.6644C32.175 25.1269 32.1848 24.3838 32.1848 23.4362C32.1848 22.4899 32.175 21.7648 32.158 21.2634C32.1227 19.3322 31.7138 17.8228 30.9324 16.7364C30.1486 15.6499 29.002 15.1061 27.4892 15.1061C25.9775 15.1061 24.8322 15.6499 24.0496 16.7364Z'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 7'
            d='M15.5905 15.1066L10.6405 22.7116C10.4482 23.006 10.2072 23.4431 9.99059 23.852C9.39542 24.9757 9.10697 26.291 9.36865 27.5497C9.77759 29.5233 11.2235 31.2114 13.3401 31.7013C14.6278 31.9996 16.014 31.7321 17.118 30.971C19.7128 29.1851 20.2045 25.6224 18.4909 23.1744C16.7054 20.6261 13.1259 20.1427 10.7428 22.556'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  );
}

export default FF60;

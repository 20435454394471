export const themeHexChanger = hex => {
  switch (hex) {
    case "EagleNebula":
      return "#F79400";
      break;
    case "Earth":
      return "#0CCA9C";
      break;
    case "TOI700d":
      return "#6ABDEF";
      break;
    case "Moon":
      return "#B9B9B9";
      break;
    case "Jupiter":
      return "#E88476";
      break;
    case "IC3639":
      return "#BD84D3";
      break;
    case "Noted":
      return "#FF3557";
      break;
    default:
      return "#FF3557";
  }
};

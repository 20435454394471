import * as React from "react";

function Pause({ theme }) {
  return (
    <svg
      width='100%'
      height='80px'
      viewBox='0 0 26 28'
      version='1.1'
      fill={theme}

      //   style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'
    >
      <path
        d='M0,4.012C0,1.796 1.788,0 4.01,0L5.99,0C8.205,0 10,1.794 10,4.012L10,23.988C10,26.204 8.212,28 5.99,28L4.01,28C1.795,28 0,26.206 0,23.988L0,4.012Z'
        fill={theme}
        // style='fill:rgb(255,53,87);'
      />
      <path
        d='M16,4.012C16,1.796 17.788,0 20.01,0L21.99,0C24.205,0 26,1.794 26,4.012L26,23.988C26,26.204 24.212,28 21.99,28L20.01,28C17.795,28 16,26.206 16,23.988L16,4.012Z'
        fill={theme}

        // style='fill:rgb(255,53,87);'
      />
    </svg>
  );
}

export default Pause;

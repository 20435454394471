// <?xml version="1.0" encoding="UTF-8" standalone="no"?>
// <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">

import * as React from "react";

function Play({ theme }) {
  return (
    <svg
      width='100%'
      height='80px'
      viewBox='0 0 26 28'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      fill={theme}
      // style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'
    >
      <clipPath id='_clip1'>
        <rect x='1' y='1' width='24px' height='26px' />
      </clipPath>
      <g clipPath='url(#_clip1)' fill='rgb(255,53,87)'>
        <path
          d='M22.914,16.806L6.808,26.561C4.749,27.808 2.173,26.249 2.173,23.755L2.173,4.245C2.173,1.749 4.749,0.192 6.808,1.439L22.914,11.194C24.974,12.441 24.974,15.559 22.914,16.806Z'
          fill={theme}
          // style={'fill:rgb(255,53,87);'}
        />
      </g>
    </svg>
  );
}

export default Play;

import { hot } from 'react-hot-loader';

import React from 'react';

import _ from 'lodash';

import Component from './BaseComponent';
import './styles/PasswordEntry.styl';

import Noted from '../assets/icoNotedIcon-Holding.png';
import Notedx2 from '../assets/icoNotedIcon-Holding@2x.png';
import { DictionaryEntry } from './DictionaryContextProvider';
import { ThemeContainer } from './ThemeContextProvider';

/*
	COMPONENT FOR USER TO ENTER PASSWORD AND DISPLAY ANY ERROR MESSAGE
	NOTE: LOADING OF NOTE AND PARSING OF ERROR MESSAGES IS MANAGED EXTERNALLY
	      THIS COMPONENT IS VERY STUPID
*/

const ERROR_TO_ID = ( value ) => {
	value = _.filter( _.split(value, ' ') ).join('_').toUpperCase();

	// console.log( value );

	return `SERVER_${value}`
}
class PasswordEntry extends Component{
	state={
		password : "",
		isPasswordHidden : true
	}

	onToggleHidden(){
		this.setState({
			isPasswordHidden : !this.state.isPasswordHidden
		});
	}

	onSubmit(){
		const {password} = this.state;
		if( _.size( password ) > 0 ){
			//now we submit
			this.props.onSubmit( password );
		}
	}

	onPasswordChange( evt ){
		//PASSWORD IS STORED LOCALLY - IT IS ONLY BUBBLED UP ON SUBMIT
		this.setState({
			password : evt.currentTarget.value
		})
	}

	// AUTO SUBMIT IF THE USER PRESSES THE ENTER KEY
	onPasswordKeyDown( evt ){
		switch( evt.key ){
			case 'Enter':
				this.onSubmit();
			break;
			default:
				//ignore
			break;
		}
	}

	render(){
		const {password,isPasswordHidden} = this.state;
		const {error} = this.props;
		return (
			<ThemeContainer className="PasswordEntry">
				{/* NO OTHER LOGO ON SCREEN AT THIS TIME - MAY AS WELL LINK TO THE OFFICIAL WEBSITE */}
				<a className="Logo" href="https://www.notedapp.io">
					<img src={Noted} alt="Noted App" srcSet={`${Notedx2} x2`} />
				</a>
				{/* DISPLAY INSTRUCTIONS */}
				<p><DictionaryEntry id="PASSWORD_PROTECTED" /></p>
				<div className={"InputField"}>
					<div className="Input">
						{/* CAN TOGGLE BETWEEN PASSWORD PROTECTED/CLEAR MODE */}
						<input type={isPasswordHidden?"password":"text"} value={password} onChange={this.onPasswordChange} onKeyDown={this.onPasswordKeyDown} />
						<button className="Eye" onClick={this.onToggleHidden} data-protected={isPasswordHidden?"true":"false"}></button>
					</div>
					<button className="Submit" data-enabled={password?true:false} disabled={!password?true:false} onClick={this.onSubmit}></button>
				</div>
				{/* DISPLAY ERROR MESSAGE */}
				{error && <p className="Error">{<DictionaryEntry id={ERROR_TO_ID(error)} defaultValue={error} />}</p>}
			</ThemeContainer>
		);
	}
}

export default hot(module)(PasswordEntry);


/*eslint-env es6*/
import React from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';
import BaseComponent from './BaseComponent';

const Context = React.createContext();

/*
	THIS IS A CONTEXT PROVIDER USED TO MANAGE TRANSLATIONS
	1. A CSV FILE CONTAINING ALL TERMS AS A DICTIONARY IS LOADED
	2. ANY COMPONENT WITHIN THE APP CAN REQUEST A DICTIONARY ENTRY BY ID
*/

class DictionaryContextProvider extends BaseComponent{
	render(){
		//
		const {children,dictionary,language} = this.props;
		return <Context.Provider value={{
			dictionary,
			language
		}}>
			{children}
		</Context.Provider>	
	}
}

DictionaryContextProvider.propTypes = {
	dictionary : PropTypes.object.isRequired,
	language : PropTypes.string.isRequired
}

// SET THE CONTEXT - REQUIRED BY REACT
DictionaryContextProvider.Context = Context;

// PROVIDE EASY ACCESS TO THE CONSUMER
const Consumer = DictionaryContextProvider.Consumer = Context.Consumer;

/*
	AN ENTRY IS A HELPER UTILITY
	1. USES A CONSUMER TO GET REFERENCE TO THE CONTEXT
	2. EXTRACTS THE DICTIONARY AND CURRENT LANGUAGE FROM THE CONTEXT
	3. PULLS THE TRANSLATION OUT OF THE DICTIONARY
*/
const Entry = DictionaryContextProvider.Entry = ( props ) => {
	const {id,defaultValue,children} = props;

	return <Consumer>{({dictionary,language}) => {
		// value will default to props.defaultValue first...
		// and then to a Developer warning message to indicate which translation is missing
		const value = dictionary.getWordById( id, language ) || defaultValue || dictionary.getWordById( id ) || `[${id}(${language})]`;

		//if there is a child function (React component) then the value is passed through to it
		if( _.isFunction( children ) ){
			return children( value );
		}
		
		//otherwise assumes we to return the value
		return value;
	}}</Consumer>
}

export default DictionaryContextProvider;
//expose all Public Classes
export {DictionaryContextProvider,Entry as DictionaryEntry,Consumer as DictionaryConsumer};
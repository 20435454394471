import { hot } from "react-hot-loader";

import React from "react";
import PropTypes from "prop-types";

import Component from "./BaseComponent";

import "./styles/Controls.styl";
import ProgressBar from "./ProgressBar";

// Dynamic SVG Icons

import Play from "../assets/dynamic_SVGs/Play";
import Pause from "../assets/dynamic_SVGs/Pause";
import FF10_D from "../assets/dynamic_SVGs/FF10";
import FF20_D from "../assets/dynamic_SVGs/FF20";
import FF30_D from "../assets/dynamic_SVGs/FF30";
import FF60_D from "../assets/dynamic_SVGs/FF60";
import FR10_D from "../assets/dynamic_SVGs/FR10";
import FR20_D from "../assets/dynamic_SVGs/FR20";
import FR30_D from "../assets/dynamic_SVGs/FR30";
import FR60_D from "../assets/dynamic_SVGs/FR60";

import { stringifyTime } from "../util/Time";
import { MENU_OPTION_SETTINGS } from "../constants/Options";
import { ThemeContainer } from "./ThemeContextProvider";

/*
	THESE ARE THE PLAYBACK CONTROLS THAT APPEAR ACROSS THE TOP OF THE SCREEN
*/

class Controls extends Component {
  onTogglePlayPause() {
    const { isPlaying } = this.props;
    if (isPlaying) {
      this.props.onPause();
    } else {
      this.props.onPlay();
    }
  }

  onRewind() {
    // use the current time and the configurable skipTime to determine where to rewind to
    this.props.onSkip(this.props.currentTime - this.props.skipTime);
  }

  onForward() {
    // use the current time and the configurable skipTime to determine where to forward to
    this.props.onSkip(this.props.currentTime + this.props.skipTime);
    console.log(this.props.skipTime);
  }

  onSpeedChange(option) {
    //bubble up the speed change using generic onUpdate prop
    this.props.onUpdate({
      speed: option.value
    });
  }

  onProgressDragStart() {
    //during the drag we want to disable playback - but need to result previous state on release
    //therefore - record state now
    this.wasPlaying = this.props.isPlaying;

    //pause whilst we drag
    if (this.props.isPlaying) {
      //bubble up request to pause
      this.props.onPause();
    }
  }

  onProgressDragUpdate(time) {
    //on update of progress drag we simply skip
    this.props.onSkip(time);
  }

  onProgressDragEnd() {
    //on release of drag we restore the original playback state
    if (this.wasPlaying === true) {
      //resume playback
      this.props.onPlay();
      //we don't this now - delete it to avoid unwanted behaviour
      delete this.wasPlaying;
    }
  }

  onOpenSpeedSettings() {
    //user has clicked the playback speed - bubble up request to open the releated setting
    this.props.onOpenSettings(MENU_OPTION_SETTINGS);
  }

  render() {
    //extract our props
    const {
      timestamps,
      currentTime,
      skipTime,
      //duration,
      speed,
      isPlaying,
      onSkip,
      theme
    } = this.props;

    //rounding the duration so that the displays sync
    var duration = Math.round(this.props.duration);
    var time = Math.min(currentTime, duration);

    return (
      <ThemeContainer theme='blue' className='Controls'>
        {/* CONTENT REQUIRED TO POSITION RELATIVE - RESETTING ORIGIN */}
        <div className='Content'>
          {/* HEADER CONTAINS THE MAIN CONTROLS */}

          <div className='Header'>
            {/* SET THE ICON ON THE REWIND BUTTON TO MATCH SKIPTIME (TODO: IMPROVE) */}
            <div className='ffContainer' onClick={this.onRewind}>
              {
                {
                  10: <FR10_D theme={theme} />,
                  20: <FR20_D theme={theme} />,
                  30: <FR30_D theme={theme} />,
                  60: <FR60_D theme={theme} />
                }[skipTime]
              }
            </div>
            <div className='Center'>
              {/* TOGGLE PLAYBACK */}
              {isPlaying ? (
                <div
                  className='playpause'
                  style={{ paddingTop: "5px", cursor: "pointer" }}
                  onClick={this.onTogglePlayPause}
                >
                  <Pause theme={theme} />
                </div>
              ) : (
                <div
                  className='playpause'
                  style={{ paddingTop: "5px", cursor: "pointer" }}
                  onClick={this.onTogglePlayPause}
                >
                  <Play theme={theme} />
                </div>
              )}

              {/* DISPLAY CURRENT TIME */}
              <p className='Clock' onClick={this.onTogglePlayPause}>
                {stringifyTime(currentTime)}
              </p>
              {/* DISPLAY CURRENT SPEED */}
              <p className='Speed' onClick={this.onOpenSpeedSettings}>
                {speed}x
              </p>
            </div>
            {/* SET THE ICON ON THE FORWARD BUTTON TO MATCH SKIPTIME (TODO: IMPROVE) */}
            <div className='ffContainer' onClick={this.onForward}>
              {
                {
                  10: <FF10_D theme={theme} />,
                  20: <FF20_D theme={theme} />,
                  30: <FF30_D theme={theme} />,
                  60: <FF60_D theme={theme} />
                }[skipTime]
              }
            </div>
          </div>
          {/* PROGRESS BAR - INCLUDES TIME REMAINING */}
          <ProgressBar
            //includes list of events in the note (timestamps)
            events={timestamps}
            //progress calculated from both the current time and total duration
            //IMPORTANT - WE'RE PASSING THE LIMITED VALUE time INSTEAD OF currentTime. time CAN NOT BE MORE THAN duration.
            currentTime={time}
            duration={duration}
            //EVENTS TO BE HANDLED
            onSkip={onSkip}
            onDragStart={this.onProgressDragStart}
            onDragUpdate={this.onProgressDragUpdate}
            onDragEnd={this.onProgressDragEnd}
          />
        </div>
      </ThemeContainer>
    );
  }
}

Controls.propTypes = {
  speed: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  skipTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

Controls.defaultProps = {
  onPlay: () => {},
  onPause: () => {},
  onSkip: () => {},
  onUpdate: () => {}
};

export default hot(module)(Controls);

/*eslint-env es6*/
import React from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';
import BaseComponent from './BaseComponent';

const Context = React.createContext();

/*
	THIS IS A CONTEXT PROVIDER USED TO MANAGE THEMES
	2. ANY COMPONENT WITHIN THE APP CAN REQUEST THE CURRENT THEME ID
*/

class ThemeContextProvider extends BaseComponent{
	render(){
		//
		const {children,theme} = this.props;
		return <Context.Provider value={{
			theme
		}}>
			{children}
		</Context.Provider>	
	}
}

ThemeContextProvider.propTypes = {
	theme : PropTypes.string.isRequired
}

// SET THE CONTEXT - REQUIRED BY REACT
ThemeContextProvider.Context = Context;

// PROVIDE EASY ACCESS TO THE CONSUMER
const Consumer = ThemeContextProvider.Consumer = Context.Consumer;

/*
	AN ENTRY IS A HELPER UTILITY
	1. USES A CONSUMER TO GET REFERENCE TO THE CONTEXT
	2. EXTRACTS THE DICTIONARY AND CURRENT LANGUAGE FROM THE CONTEXT
	3. PULLS THE TRANSLATION OUT OF THE DICTIONARY
*/
const Container = ThemeContextProvider.Container = ( props ) => {
	const {as,children} = props;
	const ComponentName = props.as || 'div';

	return <Consumer>{({theme}) => {
		//display the component
		return <ComponentName data-theme={theme} {...props} />
	}}</Consumer>
}

export default ThemeContextProvider;
//expose all Public Classes
export {ThemeContextProvider,Container as ThemeContainer,Consumer as ThemeConsumer};
import * as React from "react";

function FF10({ theme = "red" }) {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 46 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icoFF10'>
        <g id='Icons/icoFF20'>
          <path
            id='Stroke 9'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M21.2068 15.8712C20.3219 17.0977 19.8631 18.8013 19.8217 20.9806L19.7937 23.4325L19.8217 25.9473C19.8631 28.169 20.3219 29.8829 21.2068 31.0876C22.0904 32.2936 23.3829 32.8953 25.0893 32.8953C26.7969 32.8953 28.0907 32.2936 28.9755 31.0876C29.8567 29.8829 30.318 28.169 30.3594 25.9473C30.3776 25.3405 30.3886 24.5022 30.3886 23.4325C30.3886 22.364 30.3776 21.5463 30.3594 20.9806C30.318 18.8013 29.8567 17.0977 28.9755 15.8712C28.0907 14.6446 26.7969 14.0313 25.0893 14.0313C23.3829 14.0313 22.0904 14.6446 21.2068 15.8712Z'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 11'
            d='M14.5651 14.4116V32.5158'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 13'
            d='M36.2625 15.796L42.4746 18.5487L44.2856 11.8102'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 15'
            d='M23.0266 45.2605C12.1895 45.7954 2.80805 37.383 1.54834 26.0005C0.286205 14.6181 7.57667 4.14595 18.2361 2.02324C28.8979 -0.0981919 39.2956 6.85238 42.0439 17.9391'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
        </g>
      </g>
    </svg>
  );
}

export default FF10;

const he = require('he');
const _ = require('lodash');
const csv = require('csv');

// A DICTIONARY CAN LOAD AND PULL INFORMATION FROM A CSV FILE - PROVIDING BASIC TRANSLATION FUNCTION

export const loadCsv = ( pathToXlsx ) => {
	return fetch( pathToXlsx )
	.then( data => data.text() )
	.then( parseCsv )
};

export const parseCsv = ( data ) => {
	return new Promise( ( resolve, reject ) => {
		csv.parse(data, function(err, data){
			if( err ){
				reject( err );
			}else{
				const keys = _.first( data );
				//ensure the first key is "id"
				keys[0] = "id";
				//map the items
				const items = _.map( _.slice( data, 1 ), ( item ) => {
					var i = 0;
					return _.keyBy( item, () => {
						return keys[i++];
					} );
				} );
				resolve( {
					keys : _.slice( keys, 1 ),
					items
				} );
			}
		});
	} )
};

export const create = ( items, options ) => new Dictionary( items, options );

//UTILITIES
function Dictionary( items, options ){
	this.items = items;
	this.options = options || {};
	this.keys = this.options.keys || {};
}

Dictionary.prototype.getById = function( id ){
	const IDENTIFIER = this.getKey('id');
	return _.find( this.items, {[IDENTIFIER]:id});
}

Dictionary.prototype.getKey = function( id ){
	return this.keys[ id ] || id;
}

Dictionary.prototype.getWordById = function( id, language ){
	//get the language
	language = language || this.options.defaultLanguage;
	const htmlEncoded = this.options.defaultHtmlEncoding ===  true ? true : false;

	console.assert( language, 'Language needs to be set by default or provided when requesting a word' );

	const DICT = this.getById( id );
	const LANGUAGE = this.getKey( language );
	
	let term = DICT ? DICT[LANGUAGE] : null;

	if( htmlEncoded ) term = he.encode( term );

	return term;
}


Dictionary.prototype.getAll = function(){
	return this.items;
}

Dictionary.prototype.filterAll = function( filter ){
	return _.filter( this.items, filter );
}
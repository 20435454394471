import { hot } from 'react-hot-loader';

import React from 'react';
import PropTypes from 'prop-types';

import Component from './BaseComponent';

import './styles/ProgressBar.styl';
import { stringifyTime } from '../util/Time';
import { limit } from '../util/Math';

import _ from 'lodash';
import {DRAG_EVENTS} from '../constants/DragEvents';
import { ThemeContainer } from './ThemeContextProvider';

const $ = window.$;

/*
	PROGRESSBAR - SELF CONTAINED TO DISPLAY CURRENT PLAYBACK TIME RESPOND TO USER INTERACTION
*/


class ProgressBar extends Component {
	
	onInteractionUpdate( evt ){
		switch( evt.type ){
			case 'mousedown':
			case 'touchstart':
				this.addEventListeners();
				this.props.onDragStart();
				this.props.onDragUpdate( this.updateDragProgress( evt ) );
			break;
			case 'mousemove':
			case 'touchmove':
				//get the position of the mouse relative to the position and size of the slider
				this.props.onDragUpdate( this.updateDragProgress( evt ) );
			break;
			case 'mouseup':
			case 'touchend':
				this.removeEventListeners();
				this.props.onDragEnd();
			break;
			default:
				window.log(`Unexpected mouse event ${evt.type}`);
			break;
		}
	}

	componentWillUnmount(){
		this.removeEventListeners();
	}

	onRefSlider( ref ){
		if( ref ){
			this.slider = ref;
		}
	}

	updateDragProgress( evt ){
		if( !_.isUndefined( this.slider ) ){
			const {duration} = this.props;
			const {pageX} = _.first( evt.touches ) || evt;

			const {left:sliderX} = $(this.slider).offset();
			const sliderWidth = $(this.slider).width();

			var x = pageX - sliderX;
			var currentTime = duration * limit(x / sliderWidth, 0, 1);

			return currentTime;
		}

		return 0;
	}

	addEventListeners(){
		_.each( DRAG_EVENTS, ( eventName ) => {
			window.addEventListener( eventName, this.onInteractionUpdate);
		} );
	}
	
	removeEventListeners(){
		_.each( DRAG_EVENTS, ( eventName ) => {
			window.removeEventListener( eventName, this.onInteractionUpdate);
		} );
	}

	render(){
		const {currentTime,duration,events} = this.props;
		return <ThemeContainer className="ProgressBar">
			{/* THUS IS THE BACKGROUND GREY BAR */}
			<div className="Slider" ref={ _.isUndefined( this.slider ) ? this.onRefSlider : null} onMouseDown={this.onInteractionUpdate} onTouchStart={this.onInteractionUpdate}>
				{/* THESE ARE MARKERS TO INDICATE EVENTS ON THE TIMELINE */}
				{_.map(events, ( event, index ) => {
					return <div key={index} className="Event" style={{left:`${Math.round(100*Math.min(1,event.duration/duration))}%`}} />
				})}
				{/* THIS IS THE PROGRESS BAR FILL */}
				<div className="Progress" style={{width:`${Math.round(100*Math.min(1,currentTime/duration))}%`}} />
			</div>
			{/* THIS DISPLAY THE AMOUNT OF TIME REMAINING */}
			<div className="Time">-{stringifyTime( duration - currentTime )}</div>
		</ThemeContainer>
	}
}

ProgressBar.propTypes = {
	onDragStart : PropTypes.func.isRequired,
	onDragUpdate : PropTypes.func.isRequired,
	onDragEnd : PropTypes.func.isRequired,
}

ProgressBar.defaultProps = {
	onDragStart : () => {},
	onDragUpdate : () => {},
	onDragEnd : () => {},
}

export default hot(module)(ProgressBar);

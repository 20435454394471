import { hot } from "react-hot-loader";

import React from "react";

import PropTypes from "prop-types";
import Component from "./BaseComponent";

import "./styles/Menu.styl";

import ICON_BACK from "../assets/btnBack.svg";
import { ThemeContainer } from "./ThemeContextProvider";

/*
	THIS IS THE SLIDE OUT MENU
	NOTE: THE CONTENTS OF THE MENU ARE DEFINED IN Note.js, THIS CLASS PROVIDES THE TEMPLATES REQUIRED TO GENERATE CONTENT
*/

class Menu extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.props.isOpen) {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.props.onClose();
      }
    }
  }

  render() {
    const { isOpen, children, onClose } = this.props;
    return (
      <ThemeContainer
        className={"Menu"}
        data-state={isOpen ? "open" : "closed"}
      >
        <div ref={this.setWrapperRef} className='menu-box'>
          <img
            style={{ cursor: "pointer" }}
            alt='Back Button'
            src={ICON_BACK}
            className='Back'
            onClick={onClose}
          />
          {/* CONTENT IS PASS THROUGH */}
          {children}
        </div>
      </ThemeContainer>
    );
  }
}

/*
	THIS IS THE MENU HEADER
*/
class MenuHeader extends Component {
  render() {
    //DYNAMICALLY ADD THE ONCLICK TO EACH CHILD SO THAT THE EVENT CAN BE MANAGED AT A ROOT LEVEL - INSTEAD OF ON EACH INDIVIDUAL CHILD
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        onClick: this.props.onOptionSelect
      });
    });
    return <ThemeContainer className='Header'>{children}</ThemeContainer>;
  }
}

/*
	THIS IS THE MAIN CHILD OF MENU HEADER
	NOTE: IT WILL BUBBLE UP THE ONCLICK EVENT - PASSING ANY PROPS IT HAS ASSOCIATED WITH IT
*/
class MenuHeaderOption extends Component {
  onClick() {
    this.props.onClick(this.props);
  }

  render() {
    const { selected, children } = this.props;
    return (
      <ThemeContainer
        onClick={this.onClick}
        className='Option'
        data-selected={selected}
      >
        {children}
      </ThemeContainer>
    );
  }
}

MenuHeaderOption.propTypes = {
  onClick: PropTypes.func.isRequired
};

MenuHeaderOption.defaultProps = {
  onClick: () => console.warn("onClick has not been defined")
};

/*
	THIS IS THE MENU CONTENT (SITS BELOW THE MENU HEADER)
*/
class MenuContent extends Component {
  render() {
    const { children } = this.props;
    return <ThemeContainer className='Content'>{children}</ThemeContainer>;
  }
}

//THIS MAKES IT EASIER FOR US TO ACCESS THE MENU COMPONENTS ELSEWHERE
Menu.Header = MenuHeader;
Menu.Header.Option = MenuHeaderOption;
Menu.Content = MenuContent;

export default hot(module)(Menu);

//AN ALTERNATIVE WAY TO ACCESS MENU COMPONENTS
export { Menu, MenuHeader, MenuHeaderOption, MenuContent };

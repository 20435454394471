import { hot } from 'react-hot-loader';

import React from 'react';

import './styles/OhNo404.styl';

import Astronaut404 from '../assets/404.png';
import Astronaut404x2 from '../assets/404@3x.png';

import { DictionaryEntry } from './DictionaryContextProvider';
import { ThemeContainer } from './ThemeContextProvider';

/*
	A SIMPLE PAGE TO DISPLAY WHEN CONTENTS CAN NOT BE FOUND
*/
const OhNo404 = () => (
	<ThemeContainer className="OhNo404">
		<a className="Logo" target="_blank" rel="noopener noreferrer" href="https://www.notedapp.io">Noted App | Everything noted.</a>
		<h1><DictionaryEntry id="404_TITLE" /></h1>
		<p><strong><DictionaryEntry id="404_SUBTITLE" /></strong></p>
		<img alt="Astronaut is lost and drifting in space" src={Astronaut404} srcSet={`${Astronaut404} 1x, ${Astronaut404x2} 2x`} />
		<p><DictionaryEntry id="404_CONTENT" /></p>
	</ThemeContainer>
)

export default hot(module)(OhNo404);


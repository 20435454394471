// <?xml version="1.0" encoding="UTF-8" standalone="no"?>
// <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">

import * as React from "react";

function SideMenu({ theme = "red" }) {
  return (
    <svg
      width='100%'
      height='20'
      viewBox='0 0 26 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.5 3C5.5 4.38071 4.38071 5.5 3 5.5C1.61929 5.5 0.5 4.38071 0.5 3C0.5 1.61929 1.61929 0.5 3 0.5C4.38071 0.5 5.5 1.61929 5.5 3Z'
        stroke={theme}
      />
      <path
        d='M15.5 3C15.5 4.38071 14.3807 5.5 13 5.5C11.6193 5.5 10.5 4.38071 10.5 3C10.5 1.61929 11.6193 0.5 13 0.5C14.3807 0.5 15.5 1.61929 15.5 3Z'
        stroke={theme}
      />
      <path
        d='M25.5 3C25.5 4.38071 24.3807 5.5 23 5.5C21.6193 5.5 20.5 4.38071 20.5 3C20.5 1.61929 21.6193 0.5 23 0.5C24.3807 0.5 25.5 1.61929 25.5 3Z'
        stroke={theme}
      />
    </svg>
  );
}

export default SideMenu;

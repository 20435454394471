import * as React from "react";

function FR20({ theme = "red" }) {
  return (
    <svg
      width='47'
      height='47'
      viewBox='0 0 46 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icoRewind20'>
        <g id='Group 9'>
          <path
            id='Stroke 1'
            d='M9.13229 15.7959L2.91896 18.5486L1.10791 11.8102'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 3'
            d='M22.3682 45.2605C33.2041 45.7954 42.5856 37.383 43.8465 26.0005C45.1086 14.6181 37.8169 4.14595 27.1575 2.02324C16.4969 -0.0981919 6.09918 6.85238 3.35095 17.9391'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 5'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M26.4095 16.7363C25.6245 17.8227 25.218 19.3322 25.1814 21.2633L25.1571 23.4362L25.1814 25.6643C25.218 27.634 25.6245 29.1512 26.4095 30.2196C27.1921 31.288 28.3374 31.8203 29.849 31.8203C31.3619 31.8203 32.5084 31.288 33.291 30.2196C34.0736 29.1512 34.4826 27.634 34.5179 25.6643C34.5349 25.1269 34.5446 24.3837 34.5446 23.4362C34.5446 22.4899 34.5349 21.7647 34.5179 21.2633C34.4826 19.3322 34.0736 17.8227 33.291 16.7363C32.5084 15.6499 31.3619 15.106 29.849 15.106C28.3374 15.106 27.1921 15.6499 26.4095 16.7363Z'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 7'
            d='M21.8322 31.8177H12.9303C12.9303 31.8177 19.4893 24.6434 20.682 22.3973C21.6119 20.6449 21.4975 18.3871 20.3632 16.807C19.2288 15.2256 17.3557 14.6804 15.7223 15.4544C14.0902 16.2284 13.0678 18.148 13.1895 20.209'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  );
}

export default FR20;

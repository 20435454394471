import _ from 'lodash';

// THIS CONTAINS SOME CONFIGURATIONS FOR THE APPLICATION

export const MENU_OPTION_TIMESTAMPS = "TIMESTAMPS";
export const MENU_OPTION_INFORMATION = "INFORMATION";
export const MENU_OPTION_SETTINGS = "SETTINGS";

export const SPEEDS = _.map([
	0.5,0.75,1,1.5,1.75,2
], ( value ) => {
	return {value,label:`${value}x`}
});

export const SKIP_DURATIONS = _.map([
	10,20,30,60
], ( value ) => {
	return {value,label:`${value}s`}
});
import * as React from "react";

function FR10({ theme = "red" }) {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 46 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icoRewind10'>
        <g id='Icons/icoRewind10'>
          <path
            id='Stroke 1'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M23.6411 15.8711C22.7563 17.0977 22.2974 18.8012 22.256 20.9805L22.228 23.4324L22.256 25.9472C22.2974 28.169 22.7563 29.8828 23.6411 31.0875C24.5247 32.2935 25.8173 32.8952 27.5237 32.8952C29.2313 32.8952 30.525 32.2935 31.4099 31.0875C32.2911 29.8828 32.7523 28.169 32.7937 25.9472C32.812 25.3404 32.8229 24.5021 32.8229 23.4324C32.8229 22.364 32.812 21.5462 32.7937 20.9805C32.7523 18.8012 32.2911 17.0977 31.4099 15.8711C30.525 14.6445 29.2313 14.0312 27.5237 14.0312C25.8173 14.0312 24.5247 14.6445 23.6411 15.8711Z'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 3'
            d='M16.9994 14.0318V32.1359'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 5'
            d='M9.63253 15.7959L3.41921 18.5486L1.60815 11.8102'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 7'
            d='M22.8685 45.2605C33.7044 45.7954 43.0858 37.383 44.3467 26.0005C45.6076 14.6181 38.3172 4.14595 27.6578 2.02324C16.9971 -0.0981919 6.5982 6.85238 3.8512 17.9391'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
        </g>
      </g>
    </svg>
  );
}

export default FR10;

import { hot } from 'react-hot-loader';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Component from './BaseComponent';

import './styles/Player.styl';
import BaseComponent from './BaseComponent';

import {DRAG_EVENTS} from '../constants/DragEvents';
import { limit } from '../util/Math';
import { ThemeContainer } from './ThemeContextProvider';

const {NODE_ENV} = process.env;

const IS_AUDIO = ( src ) => {
	if( src ){
		src = src.url || src;
	}

	return _.includes(
		['amr','mpeg','opus','ogg','oga','aac','caf','m4a','weba','webm','dolby','flac','mp3','wav'],
		(_.last( _.split( _.first( _.split( src, '?') ), '.' ) ) || "").toLowerCase()
	);
}

const GET_ELEMENT_INDEX = ( element ) => {
	return Number( element.getAttribute("data-index") );
}

/**
	PLAYER CAN PLAY A SERIES OF AUDIO OR VIDEO ELEMENTS (NOT MIXED)
	FILES ARE LOADED IN TURN TO REDUCE THROTTLING
	TOTAL DURATION MUST BE PASSED TO THE COMPONENT - IT CAN NOT BE CALCULATED DYNAMICALLY AS IT HAS A DEPENEDENCY ON LOADING ALL MEDIA FIRST

	AUDIO AND VIDEO EVENTS ARE NORMALISED - EXTERNAL EVENTS ARE GENERIC AND DO NOT GIVE AWAY THE TYPE OF MEDIA WE ARE PLAYING
	BOTH MEDIA REPORT CHANGES TO currentTime AND isPlaying
 */

class Player extends Component{

	// COMPLEX STATE
	state = {
		// IF THE SRC CHANGES A NEW SET OF MEDIA ELEMENTS, RENDERID IS UPDATED TO ENSURE THIS
		renderId : 0,
		// CURRENT TIME IN TOTAL SEQUENCE
		currentTime : 0,
		// IS MEDIA CURRENTLY PLAYING
		isPlaying : false,
		// LIST OF ALL METADATA FROM MEDIA SEQUENCE
		srcMetadata : [],
		// LIST OF ALL MEDIA ELEMENTS IN SEQUENCE
		srcRefs : [],
		// LIST OF ALL CURRENT MEDIA SOURCES (STORE INTERNAL REFERENCE TO COMPARE TO PROPS)
		src : [],
		// CURRENT PROGRESS IN LOADING ITEMS FROM THE MEDIA SEQUENCE
		loadIndex : 0,
		// WHICH ELEMENT IS CURRENTLY BEING PLAYED - IMPORTANT FOR MULTIFILE VIDEO
		playbackIndex : 0,
		// ARE WE OFF SCREEN
		isOffScreen : false
	}

	componentDidMount(){
		// INIT THE MEDIA
		this.initSourceFiles();
		// INIT THE STATE
		this.setState({
			// SET THE CURRENT TIME TO START FROM
			currentTime : this.props.currentTime
		});

		this.interval = setInterval( () => {
			if( this.player ){
				//get the position of the player, is it offscreen
				var isOffScreen = this.player.getBoundingClientRect().y < 50 ? true : false;

				if( isOffScreen !== this.state.isOffScreen ){
					this.setState({isOffScreen},() => {
						this.onRenderPopover();
					});
				}
			}
		}, 1000 );
	}
	
	componentWillUnmount(){
		clearInterval( this.interval );
	}

	onScroll(){
		console.log('onScroll');
	}

	onPlayerRef( player ){
		if( !this.player ){
			this.player = player;
		}
	}

	shouldComponentUpdate( props, state ){
		//ON A CHANGE TO STATE OR PROPS...
		
		// FLAG TO INDICATE A CHANGE HAS OCCURED WE NEED TO RESPOND TO
		var hasChanged = false;
		
		// IF WE ARE PASSED NEW MEDIA SRC (DEVELOPER TESTING)
		if( !_.isEqual( props.src, this.props.src ) ){
			//RE-INIT THE MEDIA
			this.initSourceFiles( props );
			hasChanged = true;
		}
		
		// IF EXTERNAL CHANGE TO paused
		if( !_.isEqual( props.paused, this.props.paused ) ){
			if( props.paused ){
				//PAUSE PLAY
				this.pause();
			}else{
				//RESUME PLAY
				this.play();
			}
		}
		
		// IF EXTERNAL CHANGE TO speed
		if( !_.isEqual( props.speed, this.props.speed ) ){
			//UPDATE PLAYBACK SPEED
			this.updatePlaybackSpeed( props );
		}
		
		
		// IF EXTERNAL CHANGE TO volume
		if( !_.isEqual( props.volume, this.props.volume ) ){
			//UPDATE PLAYBACK VOLUME
			this.updatePlaybackVolume( props );
		}
		
		// IF EXTERNAL CHANGE TO currentTime
		if( !_.isEqual( props.currentTime, this.props.currentTime ) ){
			//UPDATE PLAY HEAD TIME
			this.playFromTime( props.currentTime, !this.props.paused );
		}
		
		//IF INTERNAL CHANGE TO currentTime
		if( !_.isEqual( state.currentTime, this.state.currentTime ) ){
			//REPORT PROGRESS TO EXTERNAL...
			this.props.onProgress( state.currentTime );
		}
		
		// IF INTERNAL CHANGE TO isPlaying
		if( !_.isEqual( state.isPlaying, this.state.isPlaying ) ){
			//REPORT STATE CHANGE TO EXTERNAL...
			this.props.onUpdate( {
				isPlaying : state.isPlaying
			} );
		}
		
		//hot-loading is messed up without using forceUpdate - this is disabled in production mode
		if( NODE_ENV === "development" ){
			this.forceUpdate();
			return true;
		}
		
		// IF INTERNAL STATE CHANGE OR hasChanged FLAG HAS BEEN SET TO true
		return hasChanged || !_.isEqual( state, this.state ) ? true : false;
	}
	
	initSourceFiles( props ){
		// WE NEED A FRESH SET OF MEDIA ELEMENTS
		// CAPTURE THEIR REFS AND METADATA AS THEY ARE LOADED IN TURN

		const {src} = props || this.props;
		//TODO - currently we're going to force the flush - shouldn't be an issue
		this.setState({
			renderId : (this.state.renderId + 1),
			currentTime : 0,
			loadIndex : 0,
			playbackIndex : 0,
			//prepare a null array so we can track which resources have been loaded and are ready
			src : _.map( src, (src) => src ),
			srcMetadata : _.map( src, () => null ),
			srcRefs : _.map( src, () => null ),
		})
	}

	onRenderPopover(){
		
		//get the current item and render it to the canvas
		if( this.canvas ){
			const {isPlaying,playbackIndex,srcRefs} = this.state;
			
			if( isPlaying || !this.canvas.hasRendered ){
				var video = srcRefs[playbackIndex];
				//draw into the canvas
				var ctx = this.canvas.getContext('2d');
				ctx.drawImage( video, 0, 0, video.videoWidth, video.videoHeight );
				//make note that we have rendered at least one frame
				this.canvas.hasRendered = true;
			}
		}

		if( this.state.isOffScreen ){
			requestAnimationFrame( this.onRenderPopover );
		}
	}

	onCanvasRef( canvas ){
		if( !this.canvas ){
			this.canvas = canvas;
		}
	}

	pause(){
		// PAUSE ALL THE MEDIA ELEMENTS
		_.map( this.state.srcRefs, ref => {
			if( ref ){
				ref.pause(); 
			}
		});
		this.setState({isPlaying:false});
	}
	
	play(){
		// PLAY FROM THE LAST RECORDED TIME
		this.playFromTime( this.state.currentTime, true );
	}

	checkPlayback( props ){
		//determine the index of the asset we should be playing
		//if it matches then set that item playing at the correct time
		const {paused,currentTime} = props || this.props;
		const {isPlaying} = this.state;

		if( !paused && !isPlaying ){
			//try and play from the requested time
			this.playFromTime( currentTime );
		}
	}

	playFromTime( time, boolForcePlay = false ){
		var t = time;
		this.setState({currentTime:time});
		//we also need the src as it will contain the true duration
		const {src:allSrcs} = this.props;
		//loop through every item until we receive something returns false
		_.every( this.state.srcMetadata, ( metadata, i ) => {
			const src = allSrcs[i];
			if( !metadata ){
				//we can't play the requested part yet as we've not loaded the information required
				return false;
			}else{
				// console.log('playFromTime',t,src.duration)
				if( t < src.duration ){
					//this is the element we need to playback
					this.playPart( i, t, boolForcePlay );
					// this.setState({currentTime:time});
					//found it - stop looking
					return false;
				}else{
					//subtract the time
					t -= src.duration;
					//keep looking
					return true;
				}
			}
		} );
	}

	playPart( index, startTime = 0, boolForcePlay = false ){
		// window.log(`playPart@${index}:${Math.round(startTime)}`,boolForcePlay);
		const {srcRefs} = this.state;
		//this will be set to true if anything starts to play
		var isPlaying = false;
		var playbackIndex = this.state.playbackIndex;
		_.each( srcRefs, (element, i) => {
			if( element ){
				if( i === index ){
					element.currentTime = startTime;
					if( boolForcePlay || !this.props.paused ){
						element.play();
						isPlaying = true;
						playbackIndex = i;
					}
				}else{
					element.pause()
				}
			}
		} );
		//update the state
		this.setState({
			isPlaying,
			playbackIndex
		});
		//inform if successful
		return isPlaying;
	}

	updatePlaybackSpeed( props ){
		const {speed} = props || this.props;

		_.each( this.state.srcRefs, ref => {
			if( ref ){
				ref.playbackRate = speed;
			}
		} );
	}
	
	updatePlaybackVolume( props ){
		const {volume} = props || this.props;

		_.each( this.state.srcRefs, ref => {
			if( ref ){
				ref.volume = volume;
			}
		} );
	}

	onAudioRef(ref){
		if( ref ){
			//read off the data-index of the element
			var index = GET_ELEMENT_INDEX( ref );
			var {srcRefs} = this.state;

			if( !isNaN( index ) ){
				//if no record of this item exists then register it
				if( srcRefs[index] === null ){
					//update the srcRefs
					srcRefs = _.clone( srcRefs );
					srcRefs[index] = ref;
					this.setState({srcRefs}, () => {
						this.updatePlaybackSpeed();
						this.updatePlaybackVolume();
					});
				}
			}
		}
	}

	onAudioLoadedMetadata( evt ){
		//get a reference to the audio
		var {target} = evt;
		//get the index of the audio
		var index = GET_ELEMENT_INDEX( target );
		//populate the metadata we need for the player
		var srcMetadata = _.clone( this.state.srcMetadata );
		//pick off the properties we want to store
		// console.log( "metadata", evt.nativeEvent, target, _.pick( target, 'duration', 'videoWidth', 'videoHeight' ) );
		var metadata = srcMetadata[index] = _.pick( target, 'duration', 'videoWidth', 'videoHeight' );
		var audioSrc = this.props.src[index];

		//set the duration on the audio src if it wasn't provided
		//NOTE: this is a temporary patch to get us through the migration of the code
		if( audioSrc && _.isUndefined(audioSrc.duration) ){
			audioSrc.duration = metadata.duration;
		}


		//target.seek( metadata.duration );

		// console.log(index,srcMetadata[index],_.sumBy(_.filter(srcMetadata),"duration"));
		//update the state
		this.setState({
			srcMetadata
		}, () => {
			//this might mean that we're ready to start playback
			this.checkPlayback();
			//if this is the last media element then we can report back to confirm the duration reported by the API
			if( (index + 1) === _.size( srcMetadata ) ){
				//report back
				// console.log( "loaded all", this.props );
				this.props.onLoaded({duration:_.sumBy(_.filter(this.props.src),"duration")});
			}
		} );
	}

	onAudioLoaded( evt ){
		this.setState({
			loadIndex : this.state.loadIndex + 1
		});

		// evt.target.currentTime = 100;
		// // evt.target.play();
		// console.log( evt.target.duration, evt.target.currentTime );
	}

	onAudioUpdate( evt ){
		var {target} = evt;
		// const {currentTime,duration} = target;

		var {srcMetadata,isPlaying,playbackIndex} = this.state;
		var {paused} = this.props;
		var index = GET_ELEMENT_INDEX( target );
		
		if( index === playbackIndex ){
			//workout the total time
			var currentTimeTotal = _.sumBy( _.filter( this.props.src, ( metadata, i ) => {
				if( i < index ){
					return metadata;
				}
				return null;
			} ), 'duration' ) + target.currentTime;
	
			// console.log( currentTimeTotal );

			//record the current time (should this go in state?)
			if( (!paused && isPlaying) ){
				this.setState({
					currentTime : currentTimeTotal
				});
			}
		}
	}
	
	onAudioEnd( evt ){
		var {srcMetadata, isPlaying, playbackIndex} = this.state;
		//only worth taking action if we're playing
		if( isPlaying ){

			var {target} = evt;
			var index = GET_ELEMENT_INDEX( target );

			// console.log( target.currentTime );

			if( index === playbackIndex ){
				if( (index + 1) < _.size( srcMetadata ) ){
					//we can play the next part
					this.playPart( index + 1 );
				}else{
					//this is the end - we should reset the currentTime to zero
					this.setState({currentTime:0,isPlaying:false}, () => {
						//report that the media has completed
						this.props.onComplete();
					});
				}
			}
		}
	}

	onAudioError( evt ){
		window.log('onAudioError', evt);
	}
	
	onClick(){
		this.props.onClick( this.props );
	}

	isVideo(){

	}
	
	render(){
		const {renderId,loadIndex,src,srcRefs,srcMetadata,playbackIndex,isOffScreen} = this.state;
		
		var videoDimensions = _.find( srcMetadata, (item, index) => {
			return item && item.videoWidth ? true : false;
		} );

		return <ThemeContainer className="Player" data-offscreen={isOffScreen?"true":"false"} ref={this.onPlayerRef} onClick={this.onClick}>
			{_.map( src, ( src, index ) => {
				//we force loading of media in order
				if( !src || index > loadIndex )return null;
				const key = `${renderId}-${index}`;
				
				return IS_AUDIO( src ) ? 
					<audio key={key} 
						data-index={index} 
						data-enabled={index === playbackIndex}
						src={src.url} 
						autoPlay={false} 
						ref={srcRefs[index] === null ? this.onAudioRef : null} 
						onTimeUpdate={this.onAudioUpdate} 
						onEnded={this.onAudioEnd}
						onLoadedMetadata={this.onAudioLoadedMetadata} 
						onLoadedData={this.onAudioLoaded}
						onError={this.onAudioError}
						/>
					:
					<video key={key} 
						data-index={index}
						data-enabled={index === playbackIndex}
						src={`${src.url}#t=0.5`} 
						autoPlay={false} 
						playsInline={true}
						preload={"true"}
						ref={srcRefs[index] === null ? this.onAudioRef : null}
						onTimeUpdate={this.onAudioUpdate} 
						onLoadedMetadata={this.onAudioLoadedMetadata} 
						onLoadedData={this.onAudioLoaded} />
			} )}
			{videoDimensions && (
				<PopOver enabled={isOffScreen}>
					<canvas ref={this.onCanvasRef} width={videoDimensions.videoWidth} height={videoDimensions.videoHeight} />
				</PopOver>
			)}
		</ThemeContainer>
	}
}

Player.propTypes = {
	paused : PropTypes.bool.isRequired,
	currentTime : PropTypes.number,
	speed : PropTypes.number,
	volume : PropTypes.number,
	onUpdate : PropTypes.func.isRequired,
	onProgress : PropTypes.func.isRequired,
	onComplete : PropTypes.func.isRequired
}

Player.defaultProps = {
	paused : true,
	speed : 1,
	volume : 1,
	onUpdate : () => console.warn('onUpdate has not been implemented'),
	onProgress : () => console.warn('onProgress has not been implemented'),
	onComplete : () => console.warn('onComplete has not been implemented')
}

export default hot(module)(Player);

const GetEventProps = ( evt ) => {
	const {target} = evt;
	const {pageX,pageY} = _.first( evt.touches ) || evt;
	return {
		target,
		pageX,
		pageY
	};
}
class PopOver extends BaseComponent{

	state={
		x : null,
		y : null,
		width : null,
		height : null
	}

	onDragStart( evt ){
		const {target,pageX,pageY} = evt;
		var {x,y,width,height} = this.state;
		if( !_.every([x,y,width,height], item => !_.isNull( item ) ) ){
			//something is null so set everything up
			const bounds = target.getBoundingClientRect();
			x = bounds.left;
			y = bounds.top;
			width = bounds.width;
			height = bounds.height;

			this.setState({width,height});
		}

		this.start = {x,y,pageX,pageY};
	}
	
	onDragUpdate( evt ){
		if( this.start ){
			var {x,y,width,height} = this.state;
			const {pageX, pageY} = evt;
			const bounds = 10;

			//update values
			x = this.start.x + pageX - this.start.pageX;
			y = this.start.y + pageY - this.start.pageY;

			x = limit( x, bounds, document.documentElement.clientWidth - width - bounds )
			y = limit( y, bounds, document.documentElement.clientHeight - height - bounds )

			this.setState({x,y});
		}
	}
	
	onDragEnd(){
		this.start = null;
	}
	
	onInteractionUpdate( evt ){
		evt.preventDefault();
		const evtProps = GetEventProps( evt );
		switch( evt.type ){
			case 'mousedown':
			case 'touchstart':
				this.addEventListeners();
				this.onDragStart( evtProps );
				this.onDragUpdate( evtProps );
			break;
			case 'mousemove':
			case 'touchmove':
				//get the position of the mouse relative to the position and size of the slider
				this.onDragUpdate( evtProps );
			break;
			case 'mouseup':
			case 'touchend':
				this.removeEventListeners();
				this.onDragEnd( evtProps );
			break;
			default:
				window.log(`Unexpected mouse event ${evt.type}`);
			break;
		}
	}

	componentWillUnmount(){
		this.removeEventListeners();
	}

	addEventListeners(){
		_.each( DRAG_EVENTS, ( eventName ) => {
			window.addEventListener( eventName, this.onInteractionUpdate);
		} );
	}
	
	removeEventListeners(){
		_.each( DRAG_EVENTS, ( eventName ) => {
			window.removeEventListener( eventName, this.onInteractionUpdate);
		} );
	}

	render(){
		const {children,enabled=true} = this.props;
		const {x,y,width,height} = this.state;
		return (
			<div className="PopOver"
				style={{
					left : x,
					top : y,
					width : width,
					height : height,
				}}
				data-enabled={enabled === false?false:true}
				onMouseDown={this.onInteractionUpdate} 
				onTouchStart={this.onInteractionUpdate} 
				>
				{children}
			</div>
		)
	}
}

import { hot } from "react-hot-loader";

import React, { Fragment } from "react";

import PropTypes from "prop-types";
import _ from "lodash";
import Promise from "bluebird";
import Truncate from "react-truncate";

import Component from "./BaseComponent";

import "./styles/Note.styl";
import Player from "./Player";
import Controls from "./Controls";
import Menu from "./Menu";

import { parseTime, stringifyTime } from "../util/Time";
import {
  MENU_OPTION_TIMESTAMPS,
  MENU_OPTION_INFORMATION,
  MENU_OPTION_SETTINGS,
  SPEEDS,
  SKIP_DURATIONS
} from "../constants/Options";

import ICON_SETTINGS from "../assets/icoSettings.svg";
import ICON_INFO from "../assets/icoInfo.svg";
import ICON_TIMESTAMP from "../assets/btnAddTimeStamp.svg";
import ICON_LINK from "../assets/icoLink.svg";
import ICON_MENU from "../assets/icoSideMenu.svg";
import moment from "moment";
import jwt from "jsonwebtoken";
import MenuButtons from "../assets/dynamic_SVGs/SideMenu";

import copy from "copy-to-clipboard";
import PasswordEntry from "./PasswordEntry";
import {
  DictionaryEntry,
  DictionaryConsumer
} from "./DictionaryContextProvider";
import { ThemeContainer } from "./ThemeContextProvider";

import { Helmet } from "react-helmet";

const { REACT_APP_API_URL, REACT_APP_KEY } = process.env;

const API_URL = REACT_APP_API_URL.split(";");

const $ = window.$;

const REGEX_TIMESTAMP = /(#[a-zA-Z0-9]+) ([0-9]:[0-9]+:[0-9]+)?/g;

/*
	THIS IS THE MAIN COMPONENT USED TO LOAD AND DISPLAY NOTE INFORMATION
	NOTE: IF THE NOTE IS MISSING, THIS COMPONENT BUBBLES UP RESPONSABILITY TO SHOW 404 PAGE
	NOTE: IF THE NOTE IS PASSWORD PROTECTED, THIS COMPONENT ONLY RENDERS THE PasswordEntry COMPONENT
*/
class Note extends Component {
  // A COMPLEX STATE
  state = {
    // IS THE MENU PANEL OPEN (DEFAULT: false)
    isMenuOpen: false,
    // IS NOTE PASSWORD PROTECTED (SET AFTER NOTE IS LOADED FROM API BASED ON STATUS CODE)
    isPasswordProtected: false,
    // CURRENT MENU STATE (DEFAULT: Timestamps)
    menuState: MENU_OPTION_TIMESTAMPS,
    // FLAG TO INDICATE THAT THE NOTE IS BEING LOADED
    isLoading: true,
    // IS THE MEDIA PLAYING
    isPlaying: false,
    // HAS THE MEDIA COMPLETED PLAYBACK
    isComplete: false,
    // WHAT IS THE CURRENT MEDIA TIME
    currentTime: 0,
    // WHERE SHOULD MEDIA START FROM (USED TO SKIP)
    startTime: 0,
    // DURATION SKIPPED ONREWIND/ONFORWARD
    skipTime: 10,
    // PLAYBACK SPEED
    speed: 1,
    // NOTE DATA LOADED
    data: null,
    // NOTE ERROR MESSAGE
    error: null,
    // DISPLAY/SCROLL ISSUE ON IOS REQUIRES THE HEADER HEIGHT TO BE KNOWN
    headerHeight: 0
  };

  componentDidMount() {
    const { noteId } = this.props;
    if (noteId) {
      //AUTO LOAD NOTE WHEN FIRST MOUNTED
      this.loadNote(noteId);
    }

    $(window).on("resize", this.onResize);
  }

  componentWillUnmount() {
    $(window).off("resize", this.onResize);
  }

  onResize() {
    this.onResizeHeader();
    // this.onResizeContent();
    this.forceUpdate();
    this.render();
  }

  onResizeHeader() {
    if (this.header) {
      var headerHeight = $(this.header).height();
      if (this.state.headerHeight !== headerHeight) {
        this.setState({ headerHeight });
      }
    }
  }

  onResizeContent() {
    Promise.delay(50).then(() => {
      $("[data-attachment] .title").txtTruncate({
        lines: 1
      });
    });
  }

  shouldComponentUpdate(props, state) {
    //IF THE NOTE ID CHANGES - RELOAD THE NOTE (USED BY DEVELOPER FOR TESTING CONTENT)
    if (!_.isEqual(props.noteId, this.props.noteId)) {
      //load the new note
      this.loadNote(props.noteId);
    }

    return true;
  }

  loadNote(noteId, password = null, index = 0) {
    // PASS THE NOTEID (AND PASSWORD IF PasswordEntry HAS BEEN DISPLAYED)
    this.setState(
      {
        //CLEAR PREVIOUS DATA
        data: null,
        error: null
      },
      () => {
        this.setState({ isLoading: true });

        Promise.resolve(API_URL[index])
          .then(url => {
            if (!url) {
              throw new Error("Used up all fallback APIs");
            } else {
              //THIS WAS PAINFUL - DON'T MESS WITH THIS WITHOUT TALKING TO VINCENT OR JIMI
              const options = {
                mode: "cors",
                method: "GET",
                headers: new Headers({})
              };

              const query = [];
              // IF PASSWORD PROTECTED THEN WE PASS IT IN THE QUERY STRING AS A JWT TOKEN
              if (password) {
                const token = jwt.sign({ password }, REACT_APP_KEY, {
                  algorithm: "HS256"
                });
                query.push(`token=${token}`);
              }
              //make the request
              return fetch(`${url}${noteId}?${_.join(query, "&")}`, options);
            }
          })
          .then(result => {
            // STATUS CODE IS IMPORTANT
            switch (result.status) {
              case 404:
                //trigger a 404
                if (index + 1 < _.size(API_URL)) {
                  //try loading from the next API
                  this.loadNote(noteId, password, index + 1);
                } else {
                  this.props.onNotFound();
                }
                return null;
              // DON'T THROW ERRORS ANYMORE
              // throw new Error("Not found");
              case 401:
                //attempt to extract the error
                return result
                  .json()
                  .catch(() => {
                    //default to an empty response
                    return {};
                  })
                  .then(result => {
                    //this doesn't throw an error - but we can set an error message if the users password is wrong
                    const error = result.error
                      ? result.message || result.error
                      : null;

                    this.setState({
                      isLoading: false,
                      isPasswordProtected: true,
                      //ONLY SHOW THE ERROR IF WE ATTEMPTED TO UNLOCK THE NOTE USING A PASSWORD
                      error: password ? error : null
                    });
                  });
              case 200:
                return (
                  result
                    .json()
                    //parse the data
                    .then(result => {
                      //ensure the audioURLs are in the expected format
                      result.audioURLs = _.map(result.audioURLs, audioURL => {
                        var { url, duration } = audioURL || "";
                        if (_.isString(audioURL)) {
                          url = audioURL;
                          duration = undefined;
                        }

                        return { url, duration };
                      });

                      result.timeTags.sort((a, b) => {
                        if (_.isNumber(a.duration) && _.isNumber(b.duration)) {
                          return a.duration > b.duration ? 1 : -1;
                        } else if (_.isNumber(a.duration)) {
                          return -1;
                        } else if (_.isNumber(b.duration)) {
                          return 1;
                        } else {
                          return a.title > b.title ? 1 : -1;
                        }
                      });

                      //round the duration
                      // result.duration = Math.round( result.duration );

                      //set the data and clear any previous error
                      this.setState(
                        {
                          isLoading: false,
                          data: result,
                          error: null
                        },
                        () => {
                          this.onResizeContent();
                        }
                      );
                    })
                );
              default:
                throw new Error(`Unexpected status code "${result.status}"`);
            }
          })
          .catch(err => {
            if (index + 1 < _.size(API_URL)) {
              //try loading from the next API
              this.loadNote(noteId, password, index + 1);
            } else {
              //display the error
              this.props.onNotFound();
              this.setState({
                isLoading: false,
                error: err.message
              });
            }
          });
      }
    );
  }

  skipToTime(time) {
    time = Math.max(0, Math.min(this.state.data.duration, time));

    if (time === this.state.startTime) {
      //this will cause a problem because it won't go back
      //the startTime is unchanged and the app will ignore the request
      //dirty fix - change the time very slightly
      time += 0.01;
    }
    //update the startTime which will inform the player to skip
    this.setState({
      startTime: time,
      currentTime: this.state.isPlaying ? this.state.currentTime : time
    });
  }

  onTogglePlayback() {
    this.setState({
      isPlaying: !this.state.isPlaying
    });
  }

  onPlayerProgress(currentTime) {
    const { startTime } = this.state;
    //when skipping to a time, media files often have to load to a previous point in time due to how compression works
    //this ensures that this slight rewind is hidden from the user
    currentTime = Math.max(startTime, currentTime);
    // console.log('onPlayerProgress',startTime,currentTime);

    //record the updated time to share with other components
    this.setState({ currentTime });
  }

  onPlayerUpdate(status) {
    //I don't think we care about this
  }

  onPlayerComplete() {
    this.setState(
      {
        isPlaying: false,
        isComplete: true
      },
      () => {
        //reset the time to the beginning
        //maybe we should do this onTogglePlayback instead if isComplete === true
        this.skipToTime(0);
      }
    );
  }

  onPlayerLoaded({ duration }) {
    const { data } = this.state;
    //check that the duration is similar
    // if( data ){
    // 	const diff = Math.abs( duration - data.duration );
    // 	//a small difference in values is to be expected - be tolerant
    // 	if( diff > 1 || (diff / duration) > 0.05 ){
    // 		console.warn(`Duration provided by the API did not match recordings`, duration, data.duration );
    // 		//overwrite the value from the API
    // 		this.setState({
    // 			data : _.merge({},data,{duration})
    // 		})
    // 	}
    // }
  }

  onControlsPlay() {
    //start playback
    this.setState({ isPlaying: true });
  }

  onControlsPause() {
    //stop playback
    this.setState({ isPlaying: false });
  }

  onControlsSkip(time) {
    //skip to a specific time
    this.skipToTime(time);
  }

  /**
   * LEGACY FUNCTION - UPDATE TO SPEED IS MANAGED THROUGH THE MENU FOR NOW
   */
  // onControlsUpdate( props ){
  // 	//controls have changed
  // 	const {speed} = props;
  // 	this.setState({
  // 		speed
  // 	});
  // }

  /*
		THERE HAS BEEN A CLICK EVENT IN THE NOTE
		AS IT IS NOT DIRECTLY INTEGRATED WITH REACT WE MUST USE JQUERY TO DETERMINE WHAT WAS CLICKED AND HOW TO RESPOND
	*/
  onNoteClick(element) {
    //NOTE: we're going to use JQuery to check the dom to find out what was clicked
    //JQUERY for read of DOM only, not write
    const $ = window.$;
    var $el = $(element);
    //find the element with the timestamp
    var $time = $el.get(0).hasAttribute("data-time")
      ? $el
      : $el.parents("[data-time]");

    //find the parent time
    if ($time) {
      //format the time
      const seconds = parseTime($time.attr("data-time"));
      this.skipToTime(seconds);
      //start things playing
      setTimeout(() => {
        this.setState({ isPlaying: true });
      }, 100);
    }
  }

  onMenuToggle() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    });
  }

  onMenuClose() {
    this.setState({
      isMenuOpen: false
    });
  }

  onMenuOpen(state) {
    this.setState({
      isMenuOpen: true,
      menuState: state || this.state.menuState
    });
  }

  onMenuOptionSelect(props) {
    this.setState({
      menuState: props["data-state"],
      isMenuOpen: true
    });
  }

  onSettingUpdate(props) {
    const { setting, value } = props;
    this.setState({
      [setting]: value
    });
  }

  onTimestampSelect(timestamp) {
    //if the timestamp lacks a duration then find the first timestamp in the timetags with the same title that we can jump to instead
    //

    //if a duration is provided then we skip to it
    if (_.isNumber(timestamp.duration)) {
      this.skipToTime(timestamp.duration);
      //restart the player from this point
      // if( !this.state.isPlaying ){
      // 	this.setState({
      // 		isPlaying : true
      // 	});
      // }
    }

    //scroll to a specific tag - we might have a specific timestamp
    const tagIdentifier = `tag${
      timestamp.timestamp ? `[data-time="${timestamp.timestamp}"]` : ""
    }`;
    const timestamps = $(".Html").find(tagIdentifier);

    // console.log( tagIdentifier );
    var hasMatched = false;
    //find the one with the correct title
    timestamps.each((index, element) => {
      if (hasMatched) return false;
      //find the specific one we want
      const text = $(element).text();
      //check the tag for the title
      if (_.some(_.split(text, " "), val => val === timestamp.title)) {
        hasMatched = true;
        //we want to scroll to the parent block
        var pElement =
          $(element)
            .parents("tr")
            .get(0) || element;
        //scroll to this item
        const { headerHeight } = this.state;
        const $container = $("html");
        //scroll to this element
        $container.scrollTop($(pElement).offset().top - headerHeight - 30);

        $(element)
          .fadeTo(500, 0.5)
          .fadeTo(500, 1);
      }
    });

    //close up the menu - this is possibly only needed on Mobile
    this.onMenuClose();
  }

  onSubmitPassword(password) {
    this.loadNote(this.props.noteId, password);
  }

  onLanguageUpdate({ value }) {
    this.props.onLanguageChange(value);
  }

  onOpenNotedHome() {
    this.onControlsPause();
    window.open("https://www.notedapp.io", "_blank");
  }

  onRefHeader(ref) {
    this.header = ref;
    this.onResizeHeader();
  }

  render() {
    const {
      data,
      error,
      isPasswordProtected,
      isMenuOpen,
      menuState,
      isLoading,
      isPlaying,
      speed,
      currentTime,
      skipTime,
      startTime,
      headerHeight
    } = this.state;

    // window.log('startTime', startTime);

    const hasMedia = data && _.size(data.audioURLs) > 0 ? true : false;

    return (
      <>
        {/* <p>{isLoading?"LOADING....":null}</p> */}
        {!data && !isLoading && (
          <>
            {isPasswordProtected && (
              <PasswordEntry error={error} onSubmit={this.onSubmitPassword} />
            )}
            {!isPasswordProtected && error && (
              <ThemeContainer className='Note'>
                <div className='Header' ref={this.onRefHeader}>
                  <a className='Logo' onClick={this.onOpenNotedHome}>
                    Noted App | Everything noted.
                  </a>
                </div>
                {/* THIS THE NOTE CONTENT */}
                <div className='Content'>
                  <div className='Padding' style={{ height: headerHeight }} />
                  <div className='Html'>
                    <div className='Error'>{error}</div>
                  </div>
                </div>
              </ThemeContainer>
            )}
          </>
        )}
        {data && (
          <ThemeContainer
            className='Note'
            data-media-enabled={hasMedia ? "true" : "false"}
            style={{ height: `${document.documentElement.clientHeight}px` }}
          >
            <Helmet>
              <title>{data.title} | Noted.</title>
              <meta name="robots" content="noindex"/>
            </Helmet>
            <div className='Header' ref={this.onRefHeader}>
              <a className='Logo' onClick={this.onOpenNotedHome}>
                {/* Noted App | Everything noted. */}
              </a>
              <div className='MenuOpen' onClick={this.onMenuToggle}>
                {" "}
                <MenuButtons theme={this.props.theme} />
              </div>
              {/* <img
                src={ICON_MENU}
                alt='Menu Button'
                className='MenuOpen'
                onClick={this.onMenuToggle}
              /> */}
              {_.size(data.audioURLs) > 0 ? (
                <>
                  {/* ONLY ENABLE THESE IF WE HAVE CONTROLS */}
                  <Controls
                    theme={this.props.theme}
                    speed={speed}
                    timestamps={data.timeTags}
                    currentTime={currentTime}
                    skipTime={skipTime}
                    duration={data.duration}
                    isPlaying={isPlaying}
                    onSkip={this.onControlsSkip}
                    onPlay={this.onControlsPlay}
                    onPause={this.onControlsPause}
                    // onUpdate={this.onControlsUpdate}
                    onOpenSettings={this.onMenuOpen}
                  />
                </>
              ) : (
                <div className='Empty' />
              )}
            </div>
            {/* THIS THE NOTE CONTENT */}
            <div className='Content'>
              <div className='Padding' style={{ height: headerHeight }} />
              <Player
                speed={speed}
                volume={1}
                currentTime={startTime}
                paused={!isPlaying}
                src={data.audioURLs}
                onClick={this.onTogglePlayback}
                onUpdate={this.onPlayerUpdate}
                onProgress={this.onPlayerProgress}
                onComplete={this.onPlayerComplete}
                onLoaded={this.onPlayerLoaded}
              />
              <HtmlContent
                className='Html'
                html={data.content}
                onClick={this.onNoteClick}
              />
            </div>
            {/* SLIDE OUT MENU */}
            <Menu onClose={this.onMenuClose} isOpen={isMenuOpen}>
              <Menu.Header onOptionSelect={this.onMenuOptionSelect}>
                {/* USE MENU HEADER TEMPLATE TO GENERATE CONTENTS */}
                <Menu.Header.Option
                  selected={menuState === MENU_OPTION_TIMESTAMPS}
                  data-state={MENU_OPTION_TIMESTAMPS}
                >
                  <img alt='Timestamps' src={ICON_TIMESTAMP} />
                </Menu.Header.Option>
                <Menu.Header.Option
                  selected={menuState === MENU_OPTION_INFORMATION}
                  data-state={MENU_OPTION_INFORMATION}
                >
                  <img alt='Information' src={ICON_INFO} />
                </Menu.Header.Option>
                <Menu.Header.Option
                  selected={menuState === MENU_OPTION_SETTINGS}
                  data-state={MENU_OPTION_SETTINGS}
                >
                  <img alt='Settings' src={ICON_SETTINGS} />
                </Menu.Header.Option>
              </Menu.Header>
              {/* USE MENU CONTENT TEMPLATE TO GENERATE BODY OF MENU */}
              <Menu.Content>
                {/* USING A CUSTOM SWITCH (NOT react-dom) */}
                <Switch path={menuState}>
                  <Case path={MENU_OPTION_TIMESTAMPS}>
                    <Timestamps data={data} onSelect={this.onTimestampSelect} />
                  </Case>
                  <Case path={MENU_OPTION_INFORMATION}>
                    <Information data={data} />
                  </Case>
                  <Case path={MENU_OPTION_SETTINGS}>
                    <Select
                      title={
                        <DictionaryEntry id='SETTING_SKIP_FORWARD_AND_BACKWARD' />
                      }
                      options={SKIP_DURATIONS}
                      value={skipTime}
                      setting={"skipTime"}
                      onChange={this.onSettingUpdate}
                    />
                    <Select
                      title={<DictionaryEntry id='SETTING_PLAYBACK_SPEED' />}
                      options={SPEEDS}
                      value={speed}
                      setting={"speed"}
                      onChange={this.onSettingUpdate}
                    />
                    {/* <DictionaryConsumer>{({dictionary,language}) => {
										return <Select title={<DictionaryEntry id="SETTING_LANGUAGE" />} options={_.map( dictionary.keys, (id) => ({value:id,label:id}) )} value={language} onChange={this.onLanguageUpdate} />
									}}</DictionaryConsumer> */}
                  </Case>
                </Switch>
              </Menu.Content>
            </Menu>
          </ThemeContainer>
        )}
      </>
    );
  }
}

Note.propTypes = {
  noteId: PropTypes.string.isRequired
};

export default hot(module)(Note);

class HtmlContent extends Component {
  onClick(evt) {
    this.props.onClick(evt.target, this.props);
  }

  render() {
    const { html, className } = this.props;
    return (
      <div
        className={className}
        onClick={this.onClick}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }
}

class Switch extends Component {
  render() {
    const { path } = this.props;
    const child = _.find(React.Children.toArray(this.props.children), child => {
      return path === child.props.path ? true : false;
    });
    return child;
  }
}

class Case extends Component {
  render() {
    return this.props.children;
  }
}

class Timestamps extends Component {
  render() {
    const { timeTags } = this.props.data || {};
    const { onSelect } = this.props;
    return (
      <ul>
        {_.map(timeTags, (timestamp, index) => {
          if (true || (timestamp && _.isNumber(timestamp.duration))) {
            return (
              <TimestampOption
                key={index}
                data={timestamp}
                onClick={onSelect}
              />
            );
          } else {
            //we're opted to display the timestamp even if it lacks the duration
            return null;
          }
        })}
      </ul>
    );
  }
}

class TimestampOption extends Component {
  onClick(evt) {
    this.props.onClick(this.props.data);
  }
  render() {
    const { duration, title, timestamp } = this.props.data;
    return (
      <li
        data-click={`Click to jump to "${title}"`}
        onClick={(e => e.stopPropagation(), this.onClick)}
      >
        <strong>{title}</strong>
        {timestamp ? (
          <Fragment>
            <br />
            <span className='Sub'>{timestamp}</span>
          </Fragment>
        ) : null}
      </li>
    );
  }
}

// {
// 	"title": "#邁步",
// 	"duration": 87,
// 	"timestamp": "0:01:27"
// }

class Information extends Component {
  onCopyLink() {
    copy(window.location.href, { message: "Copied" });
    if (this.dict) {
      alert(this.dict["INFO_LINK_COPIED"]);
    }
  }

  // componentDidMount(){
  // 	this.onResize();

  // 	$(window).on( 'resize', this.onResize );
  // }

  // componentWillUnmount(){
  // 	$(window).off( 'resize', this.onResize );
  // }

  // onResize(){
  // 	this.count = (this.count || 0) + 1;

  // 	Promise.delay( 100 )
  // 	.then( () => {
  // 		console.log('Adjust lines', $('[data-lines]').length);
  // 		$('[data-lines]').txtTruncate({
  // 			lines : 1
  // 		})
  // 		.css({backgroundColor:['red','blue','green'][this.count%3]})
  // 	} );
  // }

  render() {
    const { title } = this.props.data || {};
    return (
      <dl>
        <dt>
          <DictionaryEntry id='INFO_NOTE_TITLE' />
        </dt>
        <dd>{title}</dd>
        <dt>
          <DictionaryEntry id='INFO_CREATED_AT' />
        </dt>
        <dd>{moment().format("D MMMM YYYY")}</dd>
        <dt>
          <DictionaryEntry id='INFO_SHARED_LINK' />
        </dt>
        <dd>
          <Truncate lines={1} ellipsis='...'>
            {window.location.href}
          </Truncate>
          <button onClick={this.onCopyLink}>
            <img alt='URL Link' src={ICON_LINK} />
            <DictionaryEntry id='INFO_COPY_LINK' />
          </button>
          <DictionaryConsumer>
            {({ dictionary, language }) => {
              this.dict = {
                INFO_LINK_COPIED:
                  dictionary.getWordById("INFO_LINK_COPIED") ||
                  dictionary.getWordById("INFO_LINK_COPIED")
              };
            }}
          </DictionaryConsumer>
        </dd>
      </dl>
    );
  }
}

class Select extends Component {
  onOptionSelect({ value }) {
    this.props.onChange(_.merge({ value }, _.omit(this.props, "value")));
  }
  render() {
    const { title, options, value } = this.props;
    return (
      <div className='Select'>
        <h3>{title}</h3>
        <ul>
          {_.map(options, (option, index) => {
            return (
              <SelectOption
                key={index}
                onSelect={this.onOptionSelect}
                selected={option.value === value}
                {...option}
              />
            );
          })}
        </ul>
      </div>
    );
  }
}

class SelectOption extends Component {
  onClick() {
    this.props.onSelect(this.props);
  }
  render() {
    const { selected, label } = this.props;
    return (
      <li
        onClick={this.onClick}
        className='SelectOption'
        data-selected={selected ? "true" : "false"}
      >
        {label}
      </li>
    );
  }
}

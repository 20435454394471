import * as React from "react";

function FF20({ theme = "red" }) {
  return (
    <svg
      width='47'
      height='47'
      viewBox='0 0 46 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icoFF20'>
        <g id='Group 9'>
          <path
            id='Stroke 1'
            d='M35.7061 15.5818L42.1786 18.5479L43.9897 11.8108'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 3'
            d='M22.729 45.2605C11.8931 45.7954 2.51166 37.383 1.25074 26.0005C-0.0114026 14.6181 7.27906 4.14595 17.9397 2.02324C28.6003 -0.0981919 38.998 6.85238 41.7463 17.9391'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 5'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M23.9746 16.7364C23.1908 17.8228 22.783 19.3322 22.7477 21.2634L22.7234 23.4362L22.7477 25.6644C22.783 27.6341 23.1908 29.1512 23.9746 30.2196C24.7572 31.2881 25.9037 31.8204 27.4153 31.8204C28.927 31.8204 30.0747 31.2881 30.8573 30.2196C31.6399 29.1512 32.0476 27.6341 32.0841 25.6644C32.1012 25.1269 32.1109 24.3838 32.1109 23.4362C32.1109 22.4899 32.1012 21.7648 32.0841 21.2634C32.0476 19.3322 31.6399 17.8228 30.8573 16.7364C30.0747 15.6499 28.927 15.1061 27.4153 15.1061C25.9037 15.1061 24.7572 15.6499 23.9746 16.7364Z'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 7'
            d='M19.3987 31.8177H10.4956C10.4956 31.8177 17.0558 24.6434 18.2473 22.3973C19.1772 20.6449 19.0628 18.3871 17.9285 16.807C16.7941 15.2256 14.9222 14.6804 13.2889 15.4544C11.6567 16.2284 10.6331 18.148 10.7548 20.209'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  );
}

export default FF20;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/icoNotedIcon-Holding.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/icoNotedIcon-Holding@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".OhNo404 {\n  width: 100vw;\n  height: 100vh;\n  background-color: #000;\n  color: #fff;\n  overflow: hidden;\n  overflow-y: auto;\n  word-break: normal;\n}\n.OhNo404 >*:last-child {\n  margin-bottom: 100px;\n}\n@media (max-width: 930px) {\n  .OhNo404 {\n    padding: 0px 20px;\n    -webkit-box-sizing: border-box /* Safari/Chrome, other WebKit */;\n    -moz-box-sizing: border-box /* Firefox, other Gecko */;\n    box-sizing: border-box /* Opera/IE 8+ */;\n  }\n}\n.OhNo404 h1 {\n  margin: 40px 0;\n  font-size: 138px;\n}\n.OhNo404 img {\n  width: 100%;\n  max-width: 300px;\n}\n.OhNo404 .Logo {\n  width: 45px;\n  height: 45px;\n  position: fixed;\n  top: 39px;\n  background-repeat: no-repeat;\n  background-size: contain;\n  z-index: 1000;\n  overflow: hidden;\n  line-height: 1000px;\n  left: calc(0.5 * (50% - 354px) - 22px);\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {\n  .OhNo404 .Logo {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  }\n}\n@media (max-width: 930px) {\n  .OhNo404 .Logo {\n    left: 25px;\n  }\n}\n", ""]);
// Exports
module.exports = exports;

import {Component} from 'react';
import _ from 'lodash';

const {NODE_ENV} = process.env;

/*
	A COMMON COMPONENT, IT PROVIDES:
	1. Auto-binding of onEvent methods
	2. Overwrites shouldComponentUpdate to fix issue with hotloading in later versions of React
*/

class BaseComponent extends Component{
	constructor ( props ){
		super( props );
		
		/*
		check our component for any methods call "on....." and auto bind them
		*/

		var prototype = null;
		//work our way down the prototype chain until we reach BaseComponent - binding any on methods as we go
		do{
			//pull out the first or the next in the chain
			prototype = Object.getPrototypeOf( prototype || this );
			//iterate
			_.each( Object.getOwnPropertyNames( prototype ), ( name ) => {
				//if name starts with on then we bind it so we keep reference to 'this'
				if( name.indexOf('on') === 0 && this[name] && typeof this[name].bind === 'function' ){
					this[name] = this[name].bind( this );
				} 
			});
		}while( prototype !== BaseComponent.prototype );
	}

	shouldComponentUpdate( props, state ){
		//issue with hotloading that means we have to manually call render 
		if( NODE_ENV === "development" ){
			this.render();
			return true;
		}

		//always return true - other components can override this behaviour
		return true;
	}
}

export default BaseComponent;
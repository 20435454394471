import * as React from "react";

function FF30({ theme = "red" }) {
  return (
    <svg
      width='47'
      height='47'
      viewBox='0 0 46 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icoFF30'>
        <g id='Group 9'>
          <path
            id='Stroke 1'
            d='M36.4652 15.796L42.6773 18.5487L44.4884 11.8102'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 3'
            d='M23.2292 45.2605C12.3921 45.7954 3.01068 37.383 1.75098 26.0005C0.488842 14.6181 7.7793 4.14595 18.4387 2.02324C29.1006 -0.0981919 39.4983 6.85238 42.2465 17.9391'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 5'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M24.5498 16.7364C23.7647 17.8228 23.3582 19.3322 23.3217 21.2634L23.2974 23.4362L23.3217 25.6644C23.3582 27.6341 23.7647 29.1512 24.5498 30.2196C25.3311 31.2881 26.4777 31.8204 27.9893 31.8204C29.5022 31.8204 30.6487 31.2881 31.4313 30.2196C32.2139 29.1512 32.6216 27.6341 32.6581 25.6644C32.6752 25.1269 32.6849 24.3838 32.6849 23.4362C32.6849 22.4899 32.6752 21.7648 32.6581 21.2634C32.6216 19.3322 32.2139 17.8228 31.4313 16.7364C30.6487 15.6499 29.5022 15.1061 27.9893 15.1061C26.4777 15.1061 25.3311 15.6499 24.5498 16.7364Z'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <path
            id='Stroke 7'
            d='M11.101 27.3422C11.2726 29.2952 12.5627 30.9358 14.3482 31.4707C16.1349 32.0068 18.0482 31.3267 19.1643 29.7594C20.2816 28.1921 20.3692 26.0604 19.3894 24.3954C18.4072 22.7317 16.556 21.8754 14.7328 22.2431L20.3254 15.2758H11.8361'
            stroke={theme}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  );
}

export default FF30;
